$(document).ready(function () {

    // Initailize Homepage-Slick-Slider
    $('.frontPageSlider').slick({
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500
    });

    // Initailize Homepage-Slick-Slider (2020-Rebrush)
    $('.homepage-header-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        fade: true,
    });

    // Initailize Reference-Slider
    $('.initializeSlickSlider').slick({
        dots: true,
        arrows: true,
        nextArrow: '<i class="fas fa-chevron-right slick-next"></i>',
        prevArrow: '<i class="fas fa-chevron-left slick-prev"></i>',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500
    });
});


//
//	Add class to Navigation on scroll
//
$(function () {
    var nav = $(".navbar");
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 120) {
            nav.addClass("scrollHeader");
        };
        if (scroll <= 80) {
            nav.removeClass("scrollHeader");
        };
    });
});


//
//   Smooth Anchor scrolling via jQuery
//
$(document).on('click', 'a[href^="#"]:not([href="#"]):not([data-toggle]):not(.nav-link):not(.navbar-toggler)', function (event) {
    event.preventDefault();
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});


//
// GA-Optout
//
$("a:contains(Google Analytics deaktivieren), a.optout, #gaOptOutLink").click(function (event) {
    $('.cc-revoke').click();
    $(this).text('Google Analytics erfolgreich deaktiviert!');
});

//
// 2-Click-Third-Party Loader
//
document.addEventListener('DOMContentLoaded', _2ClickIframePrivacy.init(''));
